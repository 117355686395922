<template>
  <div class="container">
    <div class="index_section updatePass_cs">
        <van-nav-bar
          title="忘记密码"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
        />
        <div class="iptF_cs">
          <div class="ipt_cs">
            <el-input v-model.trim="account.userName" maxlength="25" placeholder="请输入商家名称"></el-input>
          </div>
          <div class="ipt_cs ipt_b">
            <el-input v-model.trim="account.phone" maxlength="11" placeholder="请输入联系人手机号"></el-input>
            <van-button type="primary" v-if="flag == 0" @click="getauthCode">获取验证码</van-button>
            <van-button type="primary" style="background-color: #ccc; border: 1px solid #ccc; box-shadow: 0 0 20px #ccc;" v-else>{{ count }}秒后重试</van-button>
          </div>
          <div class="ipt_cs">
            <el-input v-model.trim="account.authCode" maxlength="6" placeholder="请输入短信验证码"></el-input>
          </div>
          <div class="btn_cs">
            <van-button type="info" style="border-radius: 50px; width: 100%;" @click="nextPass">下一步</van-button>
          </div>
        </div>
        <van-popup
          v-model="show"
          closeable
          position="bottom"
          :close-on-click-overlay=false
          :style="{ height: '50%' }"
        >
          <div class="popup_cs">
            <div class="popup_title">重置密码</div>
            <van-form>
                <van-field
                  v-model.trim="form.password"
                  :type="passwordOff0?'password':'text'"
                  name="新密码"
                  maxlength="18"
                  label="新密码"
                  placeholder="请输入新密码"
                >
                  <template slot="right-icon">
                    <img :src="passwordOff0?require('../../assets/img/eye-off.png'):require('../../assets/img/eye-on.png')" width="18px" height="18px" alt="" @click="passwordOff0=!passwordOff0">
                  </template>
                </van-field>

                <van-field
                  v-model.trim="form.password1"
                  :type="passwordOff1?'password':'text'"
                  name="确认密码"
                  maxlength="18"
                  label="确认密码"
                  placeholder="请输入确认密码"
                >
                  <template slot="right-icon">
                    <img :src="passwordOff1?require('../../assets/img/eye-off.png'):require('../../assets/img/eye-on.png')" width="18" height="18px" alt="" @click="passwordOff1=!passwordOff1">
                  </template>
                </van-field>
                <div style="margin: 5vh 0 0; width: 100%; text-align: center;">
                  <van-button round block type="info" style="background-color: #133676;" native-type="submit" @click="submitPass">提交</van-button>
                </div>
              </van-form>
          </div>
        </van-popup>
    </div>
  </div>
</template>

<script>
let telReg = /^1[3,4,5,6,7,8,9]{1}\d{9}$/
let posswordReg = /^(\w){6,18}$/ //只能输入6-18位字母、数字、下划线
import { Notify } from 'vant';
export default {
  data() {
    return {
      count: 60,
      flag: 0,
      show: false,
      passwordOff0: true,
      passwordOff1: true,
      // 账户验证
      account: {
        userName: '',
        phone: '',
        authCode: '',
      },
      // 重置密码
      form:{
        password: '',
        password1: '',
        dataId: '',
      },
    }
  },
  created(){
    if(!(isNaN(window.localStorage.count))){
      if(window.localStorage.count < this.count && window.localStorage.count > 0){
        this.count = window.localStorage.count;
        this.flag = 1;
        let interval = setInterval(()=>{
          this.count--;
          window.localStorage.count = this.count;
          if(this.count <= 0){
            clearInterval(interval);
            this.flag = 0;
            this.count = 60;
          }
        }, 1000) 
      }
    }
  },
  methods: {
      //验证通过下一步 
      nextPass(){
        if(this.account.userName == ''){
          Notify({ type: 'danger', message: '商家名称不能为空!!!' });
          return false;
        }
        if(this.account.phone == ''){
          Notify({ type: 'danger', message: '联系人手机号不能为空!!!' });
          return false;
        }
        if(!telReg.test(this.account.phone)){
          Notify({ type: 'danger', message: '联系人手机号格式错误!!!' });
          return false;
        }
        if(this.account.authCode == ''){
          Notify({ type: 'danger', message: '短信验证码不能为空!!!' });
          return false;
        }
        this.$http
        .ajax(
            '/ffkj-main/merchant/checkVerifyCode',
            {
                name: this.account.userName,//商家名称
                phone: this.account.phone,//联系人电话
                verifyCode: this.account.authCode,//短信验证码
            },
            'post',
            {}
          )
          .then((res) => {
            if(res.code == 0){
              this.form.dataId = res.data;
              this.show = true;
            }else{
              Notify({ type: 'danger', message: res.message });
            }
          });
      },
      // 取消
      onClickLeft() {
        this.$router.push({
          path: '/',
          query: {}
        })
      },
      // 获取短信验证码
      getauthCode(){
        if(this.account.userName == ''){
          Notify({ type: 'danger', message: '商家名称不能为空!!!' });
          return false;
        }
        if(this.account.phone == ''){
          Notify({ type: 'danger', message: '联系人手机号不能为空!!!' });
          return false;
        }
        if(!telReg.test(this.account.phone)){
          Notify({ type: 'danger', message: '联系人手机号格式错误!!!' });
          return false;
        }
        this.$http
        .ajax(
            '/ffkj-main/merchant/getVerifyCode',
            {
                name: this.account.userName,//商家名称
                phone: this.account.phone,//联系人电话
            },
            'post',
            {}
          )
          .then((res) => {
            if(res.code == 0){
              this.flag = 1;
              let interval = setInterval(()=>{
                this.count--;
                window.localStorage.count = this.count;
                if(this.count <= 0){
                  clearInterval(interval);
                  this.flag = 0;
                  this.count = 60;
                }
              }, 1000)
            }else{
              Notify({ type: 'danger', message: res.message });
            }
          }
        )
      },
      // 提交密码
      submitPass(){
        if(this.form.password == ''){
          Notify({ type: 'danger', message: '新密码不能为空!!!' });
          return false;
        }
        if(this.form.password1 == ''){
          Notify({ type: 'danger', message: '确认密码不能为空!!!' });
          return false;
        }
        if (!posswordReg.test(this.form.password)) {
          Notify({ type: 'danger', message: '只能输入6-18位字母、数字、下划线' })
          return false
        }
        if (!posswordReg.test(this.form.password1)) {
          Notify({ type: 'danger', message: '只能输入6-18位字母、数字、下划线' })
          return false
        }
        if(this.form.password != this.form.password1){
          Notify({ type: 'danger', message: '两次密码输入不一致, 请重新输入!!!' });
          return false;
        }
        this.$http
        .ajax(
            '/ffkj-main/merchant/setPwd',
            {
                id: this.form.dataId,//验证码id
                pwd: this.$md5(this.form.password1),//重置后的密码
            },
            'post',
            {}
          )
          .then((res) => {
            if(res.code == 0){
              Notify({ type: 'success', message: '操作成功' });
              this.show = false;
              this.onClickLeft();
            }else{
              Notify({ type: 'danger', message: res.message });
            }
          })
      }
  }
}
</script>
<style >
  .el-textarea__inner,.el-input__inner { 
    background: transparent !important;
  }
</style>
<style scoped>

</style>